@media screen and (max-width: 768px), print {
  .button.is-fullwidth-mobile,
  .select.is-fullwidth-mobile,
  .select.is-fullwidth-mobile select {
    display: flex;
    width: 100%;
  }
}
@media screen and (max-width: 1087px) {
  .navbar {
    .navbar-brand {
      min-height: 100px;
      height: 100px;
    }
    .navbar-burger {
      height: auto;
      padding: 0 40px;
    }
  }
  .hero-box {
    margin: 0 auto;
    width: 100%;
    .hero-title {
      font-size: 150%;
    }
    .hero-subtitle {
      font-size: 100%;
    }
  }
}
