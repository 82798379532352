.avatar-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
.user-avatar {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	margin-right: 25px;
}

.full-width {
	width: 100%;
	& * {
		width: inherit;
	}
}
