.banner {
	position: relative;
	display: flex;
	flex-direction: column;
	img{
		width: 100%;
		height: auto;
	}
}

.hero-box {
	background: $info;
	color: $white;
	.hero-title {
		font-size: 3em;
	}
	.hero-subtitle {
		font-size: 2em;
	}
}
