.flex {
	display: flex;
}
.flex-row {
	flex-direction: row;
}
.flex-column {
	flex-direction: column;
}
.flex-between {
	justify-content: space-between;
}
.flex-end {
	justify-content: flex-end;
}

.flex-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}
