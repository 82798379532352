.info-box {
	transition: all 0.3s ease-in-out;
	&.show {
		opacity: 1;
	}
	&.hide {
		opacity: 0;
	}
	&.fixed {
		position: fixed;
		bottom: 25px;
		right: 25px;
		z-index: 1;
	}
}
