.navbar {
	height: 100px;

	.navbar-brand{
		margin-left: 25px;
	}
	.navbar-end {
		margin-right: 25px;
	}
}

.navbar-burger {
	background: none;
	border: none;
}
.navbar-item {
	img {
		width: auto;
		height: 50px;
		max-height: 50px;
	}
}
